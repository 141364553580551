<template>
  <div class="w-full px-2">
    <div class="flex items-center w-full justify-center py-4">
      <span
        class="typcn typcn-arrow-left-thick leading-none text-xl hover:text-green"
        @click="changeMonth(-1)"
        role="button"
      ></span>
      <h3 class="flex px-3 w-1/4 justify-center">
        {{ currentMonthName }} {{ currentYear }}
      </h3>
      <span
        class="typcn typcn-arrow-right-thick leading-none text-xl hover:text-green"
        @click="changeMonth(1)"
        role="button"
      ></span>
    </div>

    <span class="heading-underline-small"></span>
    <div class="grid grid-cols-7 px-2 tracking-wider">
      <div class="flex justify-center">Esmaspäev</div>
      <div class="flex justify-center">Teisipäev</div>
      <div class="flex justify-center">Kolmapäev</div>
      <div class="flex justify-center">Neljapäev</div>
      <div class="flex justify-center">Reede</div>
      <div class="flex justify-center">Laupäev</div>
      <div class="flex justify-center">Pühapäev</div>
    </div>
    <div class="grid grid-cols-7 rounded-md w-full" v-if="monthInited">
      <div
        class="p-1 flex"
        v-for="item in monthArray"
        :key="item.id"
        :class="item.disabled ? 'text-offerwhite' : ''"
      >
        <div class="calendar-item">
          <div
            class="calendar-item-date"
            :class="[
              item.disabled ? 'text-offerwhite' : 'text-darkgrey',
              moment().isSame(item.fullDate, 'day')
                ? 'border-darkgreen text-darkgreen font-semibold'
                : 'bg-transparent border-offerwhite'
            ]"
            @click="openDayView(item)"
          >
            {{ item.date + "." + formatMonth(item.month) }}
          </div>
          <div class="flex flex-col p-1 h-full">
            <task v-for="task in item.tasks" :key="task.id" :task="task"></task>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Task from "./monthly/Task";
import RequestHandler from "@/assets/mixins/RequestHandler";
const monthNames = [
  { month: 0, name: "Jaanuar" },
  { month: 1, name: "Veebruar" },
  { month: 2, name: "Märts" },
  { month: 3, name: "Aprill" },
  { month: 4, name: "Mai" },
  { month: 5, name: "Juuni" },
  { month: 6, name: "Juuli" },
  { month: 7, name: "August" },
  { month: 8, name: "September" },
  { month: 9, name: "Oktoober" },
  { month: 10, name: "November" },
  { month: 11, name: "Detsember" }
];
export default {
  data() {
    return {
      currentMonth: 0,
      currentDay: 1,
      currentYear: 1970,
      timestamp: null,
      moment: moment,
      monthArray: [],
      monthInited: false,
      taskData: null,
      tasksInited: false
    };
  },
  components: {
    Task
  },
  mixins: [RequestHandler],
  methods: {
    initCalendar() {
      this.timestamp = moment();
      this.currentMonth = this.timestamp.month();
      this.currentYear = this.timestamp.year();
      this.currentDay = this.timestamp.date();
      this.initMonth();
    },
    retrieveData() {
      this.monthlyDataInited = false;
      if (this.$store.state.companyData.activeCompany) {
        this.apiRequest(
          "calendar/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/" +
            (this.$store.state.companyData.activeCompany.worker_permission ===
            "R5"
              ? "individual/"
              : "") +
            "?period=month&start_time=" +
            this.getStartOfMonth(
              moment()
                .month(this.currentMonth)
                .year(this.currentYear)
                .date(1)
            ),
          "get",
          true
        ).then(res => {
          this.taskData = res.data;
          this.tasksInited = true;
          this.initTasks();
        });
      }
    },
    getStartOfMonth(input) {
      return moment(input)
        .startOf("month")
        .format("YYYY-MM");
    },
    initMonth() {
      this.monthInited = false;
      this.monthArray = [];
      let offset = moment()
        .year(this.currentYear)
        .month(this.currentMonth)
        .date(1)
        .weekday();

      let lastMonthNumber = 0;
      let nextMonthNumber = null;

      if (this.currentMonth === 0) {
        lastMonthNumber = 11;
      } else {
        lastMonthNumber = this.currentMonth - 1;
      }
      if (this.currentMonth === 11) {
        nextMonthNumber = 0;
      } else {
        nextMonthNumber = this.currentMonth + 1;
      }

      const lastMonthLength = moment()
        .year(this.currentYear)
        .month(lastMonthNumber)
        .date(1)
        .daysInMonth();
      for (let i = 0; i < offset; i++) {
        let yearNumber = null;
        if (this.currentMonth === 0) {
          yearNumber = this.currentYear - 1;
        } else {
          yearNumber = this.currentYear;
        }

        this.monthArray.push(
          this.generateDay(
            true,
            lastMonthLength - (offset - (i + 1)),
            this.monthArray.length,
            moment()
              .year(yearNumber)
              .month(lastMonthNumber)
              .date(lastMonthLength - (offset - (i + 1)))
              .weekday(),
            moment()
              .year(yearNumber)
              .month(lastMonthNumber)
              .date(lastMonthLength - (offset - (i + 1))),
            null
          )
        );
      }
      for (
        let i = 0;
        i <
        moment()
          .year(this.currentYear)
          .month(this.currentMonth)
          .daysInMonth();
        i++
      ) {
        this.monthArray.push(
          this.generateDay(
            false,
            i + 1,
            this.monthArray.length,
            this.currentMonth,
            moment()
              .year(this.currentYear)
              .month(this.currentMonth)
              .date(i)
              .weekday(),
            moment()
              .year(this.currentYear)
              .month(this.currentMonth)
              .date(i + 1),
            null
          )
        );
      }
      if (this.monthArray.length > 28 && this.monthArray.length < 35) {
        let fillLength = 35 - this.monthArray.length;
        let yearNumber = null;
        if (this.currentMonth === 11) {
          yearNumber = this.currentYear + 1;
        } else {
          yearNumber = this.currentYear;
        }

        for (let i = 0; i < fillLength; i++) {
          this.monthArray.push(
            this.generateDay(
              true,
              i + 1,
              this.monthArray.length,
              nextMonthNumber,
              moment()
                .year(yearNumber)
                .month(nextMonthNumber)
                .date(i)
                .weekday(),
              moment()
                .year(yearNumber)
                .month(nextMonthNumber)
                .date(i + 1),
              yearNumber
            )
          );
        }
      } else if (this.monthArray.length > 35 && this.monthArray.length < 42) {
        let yearNumber = null;

        if (this.currentMonth === 11) {
          yearNumber = this.currentYear + 1;
        } else {
          yearNumber = this.currentYear;
        }
        let fillLength = 42 - this.monthArray.length;
        for (let i = 0; i < fillLength; i++) {
          this.monthArray.push(
            this.generateDay(
              true,
              i + 1,
              this.monthArray.length,
              nextMonthNumber,
              moment()
                .year(yearNumber)
                .month(nextMonthNumber)
                .date(i)
                .day(),
              moment()
                .year(yearNumber)
                .month(nextMonthNumber)
                .date(i + 1),
              yearNumber
            )
          );
        }
      }
      this.monthInited = true;
      this.retrieveData();
    },
    initTasks() {
      for (let i = 0; i < this.taskData.length; i++) {
        this.monthArray
          .find(item =>
            moment(item.fullDate).isSame(this.taskData[i].start_time, "day")
          )
          .tasks.push(this.taskData[i]);
      }
      this.sortTasks();
    },
    changeMonth(input) {
      let monthWithInput = this.currentMonth + input;
      if (monthWithInput > 11) {
        this.currentMonth = 0;
        this.currentYear += 1;
      } else if (monthWithInput < 0) {
        this.currentMonth = 11;
        this.currentYear -= 1;
      } else {
        this.currentMonth += input;
      }
      this.initMonth();
    },
    openDayView(date) {
      this.$emit("openDailyView", date);
    },
    formatMonth(month) {
      let str = "";

      if ((month + 1).toString().length === 1) {
        str = "0" + (month + 1);
      } else {
        str = month + 1;
      }
      return str;
    },
    sortTasks() {
      for (let i = 0; i < this.monthArray.length; i++) {
        this.monthArray[i].tasks.sort((a, b) => {
          if (moment(a.start_time).unix() > moment(b.start_time).unix()) {
            return 1;
          } else if (
            moment(a.start_time).unix() < moment(b.start_time).unix()
          ) {
            return -1;
          } else {
            return 0;
          }
        });
      }
    },
    generateDay(disabled, date, id, month, dayOfWeek, fullDate, year) {
      const day = {};
      day.disabled = disabled;
      day.date = date;
      day.id = id;
      day.month = month;
      day.dayOfWeek = dayOfWeek;
      year ? (day.year = year) : null;
      day.fullDate = fullDate;
      day.tasks = [];
      return day;
    }
  },
  mounted() {
    this.initCalendar();
  },
  computed: {
    currentMonthName() {
      return monthNames.find(item => item.month === this.currentMonth).name;
    }
  }
};
</script>

<style lang="scss">
.calendar-item {
  min-height: 8rem;
  @apply bg-offwhite w-full rounded-md shadow h-auto flex flex-col duration-100;
  .calendar-item-date {
    @apply flex cursor-pointer rounded-t-md px-2 py-1 mb-1 duration-100 border-b-2;
    &:hover {
      @apply bg-darkgreen text-white;
    }
  }
}
</style>
