<template>
  <div class="w-full mt-2">
    <div class="flex flex-col" v-if="dataRetrieved">
      <div
        class="flex justify-start w-full text-darkgrey font-semibold px-5 py-2 rounded-full"
      >
        <span class="mr-3">{{
          moment(date).format("D") + "." + moment(date).format("MM")
        }}</span>
        <span>{{ getDayOfWeek() }}</span>
      </div>

      <div
        class="flex mb-3 flex-col w-full overflow-x-scroll flex-1 pb-2 "
        v-dragscroll
        v-dragscroll.x="true"
      >
        <div class="flex w-full bg-white rounded-b-xl">
          <div class="flex w-full">
            <div
              class="flex w-1/12 items-center justify-center font-semibold py-1 text-white"
            ></div>
            <div class="flex w-11/12 py-1 px-1">
              <div
                class="flex justify-center"
                v-for="worker in workers"
                :style="'width: 300px;'"
                :key="worker.profile"
              >
                {{ worker.profile_name }}
              </div>
              <div class="flex justify-center" :style="'width: 300px;'">
                Töötajata
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex rounded-xl overflow-y-scroll daily-cal-window"
          id="daily-cal-window"
          v-if="dataRetrieved"
        >
          <div class="flex flex-col text-darkgrey w-1/12">
            <div class="rounded-tl-xl daily-cal-time" id="0">
              00:00
            </div>
            <div class="daily-cal-time">
              01:00
            </div>
            <div class="daily-cal-time">
              02:00
            </div>
            <div class="daily-cal-time">
              03:00
            </div>
            <div class="daily-cal-time">
              04:00
            </div>
            <div class="daily-cal-time">
              05:00
            </div>
            <div class="daily-cal-time">
              06:00
            </div>
            <div class="daily-cal-time">
              07:00
            </div>
            <div class="daily-cal-time">
              08:00
            </div>
            <div class="daily-cal-time">
              09:00
            </div>
            <div class="daily-cal-time">
              10:00
            </div>
            <div class="daily-cal-time">
              11:00
            </div>
            <div class="daily-cal-time">
              12:00
            </div>
            <div class="daily-cal-time">
              13:00
            </div>
            <div class="daily-cal-time">
              14:00
            </div>
            <div class="daily-cal-time">
              15:00
            </div>
            <div class="daily-cal-time">
              16:00
            </div>
            <div class="daily-cal-time">
              17:00
            </div>
            <div class="daily-cal-time">
              18:00
            </div>
            <div class="daily-cal-time">
              19:00
            </div>
            <div class="daily-cal-time">
              20:00
            </div>
            <div class="daily-cal-time">
              21:00
            </div>
            <div class="daily-cal-time">22:00</div>
            <div class="rounded-bl-xl daily-cal-time">
              23:00
            </div>
          </div>
          <div class="flex flex-row w-11/12 relative h-auto">
            <div
              class="current-time"
              id="current-time"
              v-if="showTimeline"
            ></div>
            <column
              v-for="worker in workers"
              :key="worker.id"
              :worker="worker"
              :tasks="
                taskData.filter(
                  x => x.workers.find(y => y.id === worker.id) !== undefined
                )
              "
            ></column>
            <column
              :tasks="taskData.filter(x => x.workers.length === 0)"
            ></column>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <h4>Tänased ülesanded</h4>
        <span class="h-1 bg-green rounded-md"></span>
        <list-task
          v-for="item in taskData"
          :key="item.id"
          :data="item"
        ></list-task>
      </div>
    </div>
  </div>
</template>

<script>
const dayOfWeek = [
  { number: 1, name: "Esmaspäev" },
  { number: 2, name: "Teisipäev" },
  { number: 3, name: "Kolmapäev" },
  { number: 4, name: "Neljapäev" },
  { number: 5, name: "Reede" },
  { number: 6, name: "Laupäev" },
  { number: 0, name: "Pühapäev" }
];
import moment from "moment";
import RequestHandler from "@/assets/mixins/RequestHandler";
import ListTask from "./daily/ListTask";
import Column from "./daily/Column";
import { dragscroll } from "vue-dragscroll";
export default {
  props: {
    activeDate: {
      type: Object,
      default: () => {
        return moment();
      }
    }
  },
  components: {
    ListTask,
    Column
  },
  mixins: [RequestHandler],
  data() {
    return {
      moment: moment,
      taskData: null,
      dataRetrieved: false,
      workers: [],
      date: null,
      timePos: "0px"
    };
  },
  directives: {
    dragscroll: dragscroll
  },
  mounted() {
    if (this.activeDate === null) {
      this.date = moment();
    } else {
      this.date = this.activeDate;
    }
    this.retrieveDayData();
    let self = this;
    setTimeout(function() {
      self.setTimeline();
    }, 100);
  },
  beforeDestroy() {
    this.$emit("closingDayCalendar");
  },
  methods: {
    getDayOfWeek() {
      return dayOfWeek.find(item => item.number === moment(this.date).day())
        .name;
    },
    retrieveDayData() {
      this.apiRequest(
        "calendar/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/" +
          (this.$store.state.companyData.activeCompany.worker_permission ===
          "R5"
            ? "individual/"
            : "") +
          "?period=day&start_time=" +
          this.date.format("YYYY-MM-DD"),
        "get",
        true
      ).then(res => {
        this.taskData = res.data;
        this.taskData = this.taskData.sort((a, b) => {
          if (moment(a.start_time).unix() > moment(b.start_time).unix()) {
            return 1;
          } else if (
            moment(a.start_time).unix() < moment(b.start_time).unix()
          ) {
            return -1;
          } else {
            return 0;
          }
        });

        this.taskData
          .filter(item => item.workers.length > 0)
          .map(x =>
            x.workers.map(y => {
              if (this.workers.find(item => item.id === y.id) === undefined) {
                this.workers.push(y);
              }
            })
          );

        this.dataRetrieved = true;
        if (moment().isSame(this.date, "day")) {
          this.setScrollPos();
        }
      });
    },
    setScrollPos() {
      setTimeout(function() {
        document
          .getElementById("daily-cal-window")
          .scroll(0, 60 * moment().hour());
      }, 100);
    },
    setTimeline() {
      if (this.date.isSame(moment(), "day")) {
        document.getElementById("current-time").style.top =
          document.getElementById("daily-cal-window").scrollHeight *
            (moment().diff(moment().startOf("day"), "seconds") / 86400) +
          "px";
      }
    }
  },
  computed: {
    showTimeline() {
      if (this.date.isSame(moment(), "day")) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style lang="scss">
.daily-cal-window {
  @apply shadow;
  height: 60vh;
}
.daily-cal-time {
  @apply flex bg-offwhite justify-center;
  min-height: 30px;
  max-height: 30px;
}
.current-time {
  position: absolute;
  width: 100%;
  @apply bg-danger;
  height: 1px;
  opacity: 0.25;
}
.grab-bing {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: grab;
}

.grab-bing:active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: grabbing;
}
</style>
