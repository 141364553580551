<template>
  <div v-if="isMounted">
    <calendar @addTask="handleAddTask" ref="calendar"></calendar>
  </div>
</template>

<script>
import Calendar from "@/components/calendar/Calendar";
export default {
  components: {
    Calendar
  },
  data() {
    return {
      isMounted: false
    };
  },
  mounted() {
    if (
      this.$store.state.companyData.isClient ||
      this.$store.state.companyData.companies.length === 0
    ) {
      this.$router.push("/client/projects");
    }
    if (this.$route.query.task_id) {
      this.$store.dispatch("taskViewer/openTaskModal", {
        task: this.$route.query.task_id
      });
      this.isMounted = true;
    }
    this.isMounted = true;
  },
  methods: {
    handleAddTask(e) {
      let data = {
        hasPresetData: false,
        presetData: null,
        relatedProject: null,
        projectLocation: null
      };
      if (e) {
        data.presetData = e;
        data.hasPresetData = true;
      }
      this.$store.dispatch("taskViewer/openAddTaskModal", data);
    }
  }
};
</script>
