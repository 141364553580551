<template>
  <div class="w-full bg-white calendar-container">
    <div class="flex flex-col items-center justify-center mb-1">
      <div class="flex justify-between w-full mobile:hidden items-center">
        <h2>Kalender</h2>
        <div class="flex items-center">
          <a
            download
            @click.prevent="exportCalendar"
            target="_blank"
            class="mr-2"
          >
            <button class="new-button-pending">
              <span class="icon"
                ><img src="/bc21/download.svg" class="w-4 h-4 filter-to-white"
              /></span>
              <span class="label">Ekspordi</span>
            </button>
          </a>
          <div
            class="mr-2 relative"
            v-if="
              $store.state.companyData.retrieved &&
                ['R0', 'R1', 'R2'].includes(
                  $store.state.companyData.activeCompany.worker_permission
                )
            "
          >
            <button @click="importView = !importView" class="new-button-green">
              <span class="icon"
                ><img src="/bc21/upload.svg" class="w-4 h-4 filter-to-white"
              /></span>
              <span class="label">Impordi</span>
            </button>
            <div v-if="importView" class="absolute pick-file-dropdown">
              <input
                ref="calendar"
                type="file"
                accept="text/calendar"
                class="mb-2"
              />
              <button @click="importCalendar" class="new-button-green">
                <span class="icon typcn typcn-tick"></span>
                <span class="label">Kinnita</span>
              </button>
            </div>
          </div>
          <div class="flex rounded-full bg-offwhite shadow">
            <div
              class="bg-green rounded-l-full flex items-center justify-center px-2"
            >
              <img src="/bc21/calendar.svg" class="w-4 h-4 filter-to-white" />
            </div>

            <button
              @click="activeView = 1"
              class="rounded-none shadow-none hover:shadow-none focus:shadow-none text-sm hover:bg-green py-2 px-2 font-medium"
              :class="
                activeView === 1
                  ? 'bg-green text-white'
                  : 'bg-white text-green hover:text-white'
              "
            >
              Kuu
            </button>
            <button
              @click="activeView = 3"
              class="rounded-none shadow-none hover:shadow-none hover:bg-green focus:shadow-none text-sm px-2 py-2 font-medium"
              :class="
                activeView === 3
                  ? 'bg-green text-white'
                  : 'bg-white text-green hover:text-white'
              "
            >
              Nädal
            </button>
            <button
              @click="activeView = 4"
              class="rounded-none rounded-r-full shadow-none hover:bg-green hover:shadow-none focus:shadow-none text-sm px-2 py-2 font-medium"
              :class="
                activeView === 4
                  ? 'bg-green text-white'
                  : 'bg-white text-green hover:text-white'
              "
            >
              Päev
            </button>
          </div>
        </div>
      </div>
      <monthly-calendar
        v-if="activeView === 1"
        @openDailyView="goToDay"
        ref="monthly"
      ></monthly-calendar>
      <weekly-calendar-manager
        v-if="activeView === 3"
        ref="weekly"
        @openAddTaskModal="openAddTaskModal"
        @openDailyView="goToDayWeekly"
      ></weekly-calendar-manager>
      <daily-calendar
        v-if="activeView === 4"
        :active-date="dailyDate"
        @closingDayCalendar="handleDayCalendarClose"
        ref="daily"
      ></daily-calendar>
    </div>
  </div>
</template>

<script>
import MonthlyCalendar from "./MonthlyCalendar";
import DailyCalendar from "./DailyCalendarManager";
import WeeklyCalendarManager from "./WeeklyCalendarManager";
import RequestHandler from "@/assets/mixins/RequestHandler";
import moment from "moment";
import EventBus from "@/assets/mixins/EventBus";

export default {
  components: {
    MonthlyCalendar,
    DailyCalendar,
    WeeklyCalendarManager
  },
  mixins: [RequestHandler],
  data() {
    return {
      activeView: 3,
      monthlyData: null,
      monthlyDataInited: false,
      dailyDate: null,
      importView: false
    };
  },
  mounted() {
    EventBus.$on("updateTasks", () => {
      this.updateData();
    });
  },
  beforeDestroy() {
    EventBus.$off("updateTasks");
  },
  methods: {
    importCalendar() {
      if (this.$refs.calendar.files.length > 0) {
        const formData = new FormData();
        const file = this.$refs.calendar.files[0];
        const fileToAppend = new File([file], file.name, { type: file.type });
        formData.append("calendar", fileToAppend, file.name);
        this.apiRequest(
          "tasks/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/import/google/",
          "post",
          true,
          formData
        ).then(res => {
          if (res.status === 200) {
            this.$refs.calendar.value = null;
            this.importView = false;
            this.updateData();
          }
        });
      }
    },
    exportCalendar() {
      this.apiRequest(
        "tasks/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/export/google/",
        "get",
        true
      ).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "calendar.ics");
        document.body.appendChild(link);
        link.click();
      });
    },
    goToDay(e) {
      this.dailyDate = moment(e.fullDate);
      this.activeView = 4;
    },
    goToDayWeekly(e) {
      this.dailyDate = moment(e);
      this.activeView = 4;
    },
    updateData() {
      if (this.activeView === 1) {
        this.$refs.monthly.initMonth();
      } else if (this.activeView === 3) {
        this.$refs.weekly.initWeek(0);
      } else if (this.activeView === 4) {
        this.$refs.daily.retrieveDayData();
      }
    },
    openAddTaskModal(e) {
      this.$emit("addTask", e);
    },
    handleDayCalendarClose() {
      this.dailyDate = null;
    }
  },
  watch: {
    "$store.state.companyData.activeCompany": {
      deep: true,
      handler() {
        this.updateData();
      }
    }
  }
};
</script>

<style lang="scss">
.pick-file-dropdown {
  @apply absolute bg-white rounded-xl p-3 shadow-outlinedark;
  top: 105%;
  right: 0;
}
</style>
