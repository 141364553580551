<template>
  <div
    class="flex flex-col relative px-2 items-center justify-center border-r-2 border-offwhite h-full"
    :style="'width: 300px;'"
    ref="col"
  >
    <task v-for="item in tasks" :key="item.id" :data="item"></task>
  </div>
</template>

<script>
import Task from "./Task";
export default {
  props: {
    worker: {
      type: Object,
      default: null
    },
    tasks: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.col.style.height = "720px";
    });
  },
  components: {
    Task
  }
};
</script>
